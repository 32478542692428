import * as React from "react"
import {useState, useEffect} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LoadingIndicator from "../components/LoadingIndicator"

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

const WaitingPage = () => (
  <Layout>
    <LoadingIndicator />
    <h1>Getting Ready...</h1>
    <p>We are working on getting this view ready for you, just hang on a second longer...</p>
  </Layout>
);

const WaitALittleBeforeShowingNotFoundPage = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (window === undefined) {
      return;
    }
    const timer = window.setTimeout(() => {
      setReady(true);
    }, 4000);
    return () => window.clearTimeout(timer);
  }, []);

  return ready ? <NotFoundPage /> : <WaitingPage />;
}

export const Head = () => <Seo title="404: Not Found" />

export default WaitALittleBeforeShowingNotFoundPage;
